<template>
    <div
      class="ebo-gdpr-cookie-policy-banner ec-modal ec-modal-open ec-align-items-center ec-justify-content-center"
    >
      <div class="ebo-gdpr-cookie-policy-banner__content ec-modal-content">
        <header class="ebo-gdpr-cookie-policy-banner__header ec-modal-header">
          <h1 class="ec-modal-title">{{ $gettext('Cookies and Privacy') }}</h1>
        </header>
        <main class="ebo-gdpr-cookie-policy-banner__main ec-mx-4">
          <p>
            {{ $gettext('This site uses cookies to ensure you get the best experience. For more information see our') }}
            <a :href="privacyPolicyUrl" target="_blank" rel="noopener noreferrer">{{ $gettext('Privacy Notice') }}</a>
          </p>
        </main>
        <footer class="ebo-gdpr-cookie-policy-banner__footer ec-modal-footer ec-flex-column ec-align-items-center ec-flex-sm-row ec-justify-content-sm-start">
          <button
            type="button"
            class="ebo-gdpr-cookie-policy-banner__positive-btn ec-btn ec-btn-primary ec-mr-0"
            data-test="ebo-gdpr-cookie-policy-banner__positive-btn"
            @click="onAcceptCookies"
          >
            {{ $gettext('Accept') }}
          </button>
          <button
            type="button"
            class="ebo-gdpr-cookie-policy-banner__settings-btn ec-btn ec-btn-outline-primary ec-my-3 ec-my-sm-0 ec-mx-sm-3"
            data-test="ebo-gdpr-cookie-policy-banner__settings-btn"
            @click="onSettings"
          >
            {{ $gettext('Settings') }}
          </button>
          <button
            type="button"
            class="ebo-gdpr-cookie-policy-banner__negative-btn ec-btn ec-btn-outline-secondary ec-ml-0"
            data-test="ebo-gdpr-cookie-policy-banner__negative-btn"
            @click="onRejectCookies"
          >
            {{ $gettext('Reject') }}
          </button>
        </footer>
      </div>
    </div>
</template>

<script>
import '@/i18n/config';

export default {
  name: 'GdprCookiePolicyBanner',
  props: {
    privacyPolicyUrl: String,
  },
  methods: {
    onAcceptCookies() {
      // eslint-disable-next-line prefer-numeric-literals
      this.$emit('accepted', parseInt('111111', 2));
    },
    onRejectCookies() {
      // eslint-disable-next-line prefer-numeric-literals
      this.$emit('rejected', parseInt('000001', 2));
    },
    onSettings() {
      this.$emit('show-overview');
    },
  },
};
</script>

<style lang="scss" scoped>
.ebo-gdpr-cookie-policy-banner {
  background: rgba(46, 54, 56, 0.2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;

  &__content {
    width: calc(100% - 24px);
    max-width: 680px;
    max-height: calc(100% - 24px);
    overflow-y: auto;
  }
}
</style>
