<template>
    <AuthWrapper :backgroundImgSrc="backgroundImgSrc" :logoSrc="logoSrc" :isEbury="isEbury">
      <Login
        v-show="showFormLogin && !showForgotPass"
        :csrfToken="csrfToken"
        :isEbury="isEbury"
        :loginError="loginError"
        :formActionUrlLogin="formActionUrlLogin"
        :formActionUrlForgot="formActionUrlForgot"
        :isSelfActivationEnabled="isSelfActivationEnabled"
      ></Login>
      <TroubleLogging v-show="!showFormLogin && !showForgotPass && !showSelfServeActivation"></TroubleLogging>
      <ForgotPassword
        v-show="showForgotPass"
        :show-back-to-login-button="showBackToLoginButton"
      ></ForgotPassword>
      <SelfServeActivation v-if="showSelfServeActivation"
                          @back-from-set-email="showLogin"
                          :isEbury="isEbury"
                          :prefilledEmail="prefilledEmail"
                          :brandName="brandName"
      />
      <GdprSettings v-if="hasGDPRCookieBannerEnabled"
                    @close-gdpr-cookie-banner="closeGDPRCookieBanner"
                    @is-gdpr-privacy-overview-banner-shown="setIsGdprPrivacyOverviewBannerShown"
                    :is-gdpr-cookie-banner-shown="isGdprCookieBannerShown"
                    :is-gdpr-privacy-overview-banner-shown="isGdprPrivacyOverviewBannerShown"
                    :gdpr-cookie-name="gdprCookieName"
                    :gdpr-cookie-version="gdprCookieVersion"
                    :privacy-policy-url="privacyPolicyUrl"
                    :windowLocation="windowLocation"
                    :csrf-token-cookie-name="csrfTokenCookieName"
      />
      <button
        type="button"
        class="ebo-auth__cookies ec-position-fixed ec-fixed-bottom ec-m-4 ec-border-0"
        v-if="hasGDPRCookieBannerEnabled"
        @click="setIsGdprPrivacyOverviewBannerShown(true)"
      >
        {{ $gettext('Manage cookies') }}
      </button>
    </AuthWrapper>
</template>

<script>
import '@/i18n/config';
import AuthWrapper from './AuthWrapper.vue';
import Login from './Login.vue';
import TroubleLogging from './TroubleLogging.vue';
import ForgotPassword from './ForgotPassword.vue';
import SelfServeActivation from './SelfServeActivation.vue';
import GdprSettings from './GdprSettings.vue';

export default {
  name: 'Auth',

  components: {
    AuthWrapper,
    Login,
    TroubleLogging,
    ForgotPassword,
    SelfServeActivation,
    GdprSettings,
  },

  props: {
    isEbury: Boolean,
    logoSrc: String,
    backgroundImgSrc: String,
    csrfToken: String,
    formActionUrlLogin: String,
    formActionUrlForgot: String,
    loginError: Boolean,
    isSelfActivationEnabled: Boolean,
    brandName: String,
    privacyPolicyUrl: String,
    hasGDPRCookieBannerEnabled: Boolean,
    gdprCookieName: String,
    gdprCookieVersion: Number,
    windowLocation: {},
    csrfTokenCookieName: String,
  },

  data() {
    return {
      showFormLogin: true,
      showForgotPass: false,
      showSelfServeActivation: false,
      prefilledEmail: null,
      showBackToLoginButton: true,
      isGdprCookieBannerShown: true,
      isGdprPrivacyOverviewBannerShown: false,
    };
  },

  created() {
    // Switching the components' visibility
    this.$bus.$on('toggle-trouble-logging', () => {
      this.showLogin();
    });

    // Switching the components' visibility
    this.$bus.$on('toggle-forgot-password', (payload) => {
      if (payload) {
        const { showGoToLoginButton } = payload;
        this.showBackToLoginButton = showGoToLoginButton;
      }
      this.showForgotPass = !this.showForgotPass;
    });

    this.$bus.$on('toggle-self-serve-activation', (payload) => {
      if (payload) {
        const { emailToActivate } = payload;
        this.prefilledEmail = emailToActivate;
      }

      this.showFormLogin = false;
      this.showForgotPass = false;
      this.showSelfServeActivation = !this.showSelfServeActivation;
    });
  },

  methods: {
    showLogin() {
      this.showFormLogin = !this.showFormLogin;
      this.showSelfServeActivation = false;
      this.showForgotPass = false;
    },
    closeGDPRCookieBanner() {
      this.isGdprCookieBannerShown = false;
    },
    setIsGdprPrivacyOverviewBannerShown(value) {
      this.isGdprPrivacyOverviewBannerShown = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.ebo-auth {
  &__cookies {
    width: fit-content;
    color: var(--ch-gray5);
    &:hover {
      color: var(--ch-main4);
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
}
</style>
