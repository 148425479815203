export const OPERATIONS_EMAIL = 'operationsteam@ebury.com'; // eslint-disable-line import/prefer-default-export

export const CODE_RESPONSE_LOGIN_USER_NOT_ACTIVATED = '1702';

export const SELF_SERVE_ACTIVATION_INFO_PAGE = 'info-page';
export const SELF_SERVE_ACTIVATION_LOADING_PAGE = 'loading';
export const SELF_SERVE_ACTIVATION_SET_EMAIL_PAGE = 'set-email';
export const SELF_SERVE_ACTIVATION_ERROR_PAGE = 'error-page';
export const SELF_SERVE_ACTIVATION_FORM_PAGE = 'form-page';
export const SELF_SERVE_ACTIVATION_SUCCESS_PAGE = 'success-page';

export const COMPANY_REGISTRATION_NUMBER = 0;
export const SIGNUP_REFERENCE = 1;
export const ACCOUNT_POSTCODE = 2;
export const TRADE_OR_PAYMENT_REF = 3;
export const MOST_RECENT_TRADE_OR_PAYMENT_REF = 4;
export const CONTACT_DATE_OF_BIRTH = 5;
export const DEALER_LAST_NAME = 6;

export const CODE_REQUEST_SUCCESS = 200;

export const GdprCategoriesFlag = {
  NECESSARY: 1,
  FUNCTIONAL: 2,
  PERFORMANCE: 4,
  ANALYTICS: 8,
  ADVERTISEMENT: 16,
  OTHERS: 32,
};
