import { render, staticRenderFns } from "./SuccessFeedback.vue?vue&type=template&id=7b708a50&scoped=true"
import script from "./SuccessFeedback.vue?vue&type=script&lang=js"
export * from "./SuccessFeedback.vue?vue&type=script&lang=js"
import style0 from "./SuccessFeedback.vue?vue&type=style&index=0&id=7b708a50&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b708a50",
  null
  
)

export default component.exports