<template>
  <div>
    <div class="ec-mb-4">
      <span>
        <span>{{ $gettext('To activate your account please enter your email address and click on ‘Continue’ to answer our security questions:') }}</span>
      </span>
    </div>
    <div class="ec-form-group" :class="{ 'ec-is-invalid': !!emailTooltipMsg }">
      <label for="username" class="ec-text-input-label"><span>{{ $gettext('Email') }}</span></label>
      <input
        type="text"
        class="ec-form-control"
        autocomplete="off"
        v-model="email"
        :placeholder="emailPlaceholder"
        @focus="cleanEmailErrors(), addHuhaInteraction(huhaTask)"
        @blur="createHuhaEventSetEmail"
        data-test="self-serve-activation-set-email__email-input"
      >
      <label v-if="!!emailTooltipMsg" for="username" class="ec-error-text-input" data-test="ec-error-text-input">{{ emailTooltipMsg }}</label>
    </div>
    <div class="ec-self-serve-activation-set-email__actions">
      <button
        class="ec-btn ec-btn-secondary ec-self-serve-activation-set-email__actions-left"
        data-test="ec-self-serve-activation-set-email__actions-negative"
        @click="back"
      >
        <span>{{ $gettext('Back') }}</span>
      </button>
      <button
        class="ec-btn ec-btn-primary ec-self-serve-activation-set-email__actions-right"
        data-test="ec-self-serve-activation-set-email__actions-positive"
        @click="setEmail"
      >
        <span>{{ $gettext('Continue') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ACTIVE_ACCOUNT_QUERY_PARAM } from '../Login.vue';

const {
  validate,
  location,
  addHuhaError,
  huha,
  addHuhaInteraction,
} = window;

export default {
  name: 'SelfServeActivationSetEmail',
  props: {
    prefilledEmail: String,
  },
  data() {
    return {
      email: '',
      emailPlaceholder: this.$gettext('contact-email@gmail.com'),
      emailTooltipMsg: '',
      huhaTask: null,
      huhaCategory: 'Activate Account via Login link',
    };
  },
  created() {
    this.huhaTask = huha.getTask('Activate Account');
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has(ACTIVE_ACCOUNT_QUERY_PARAM)) {
      this.huhaCategory = 'Activate Account via Activation link';
    }
    if (this.prefilledEmail) {
      this.email = this.prefilledEmail;
      this.setEmail();
    }
  },
  methods: {
    addHuhaInteraction,
    cleanEmailErrors() {
      this.emailTooltipMsg = '';
    },
    clean() {
      this.cleanEmailErrors();
      this.email = '';
    },
    createHuhaEventSetEmail() {
      huha.createEvent('Set Email', 'Email', 'Set', this.huhaCategory, this.email, this.huhaTask);
    },
    setEmail() {
      const errorMessage = this.validateEmail();
      addHuhaInteraction(this.huhaTask);
      huha.createEvent('Click Continue Set Email Page', 'Continue Set Email Page', 'Click', this.huhaCategory, '', this.huhaTask);

      if (errorMessage) {
        addHuhaError(this.huhaTask);
        this.emailTooltipMsg = errorMessage;
      } else {
        this.$emit('set-email', this.email);
        this.clean();
      }
    },
    validateEmail() {
      let errorText;
      const relativeUrl = location.pathname + location.search;

      if (!errorText && !this.validate('empty', this.email)) {
        errorText = this.$gettext('Email cannot be empty');
      }
      if (relativeUrl.indexOf('translations') !== -1) {
        return errorText;
      }
      if (!errorText && !this.validate('email-@', this.email)) {
        errorText = this.$gettext('Email address must contain @');
      }
      if (!errorText && !this.validate('email-dot', this.email)) {
        errorText = this.$gettext('Email address must contain .');
      }
      if (!errorText && !this.validate('email', this.email)) {
        errorText = this.$gettext('Invalid email');
      }
      return errorText;
    },
    validate(type, text) {
      return validate(type, text);
    },
    back() {
      addHuhaInteraction(this.huhaTask);
      huha.createEvent('Click Back to Login Set Email Page', 'Back to Login Set Email Page', 'Click', this.huhaCategory, '', this.huhaTask);
      this.clean();
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.ec-self-serve-activation-set-email__actions {
  display: flex;
  flex-direction: column;

  &-right {
    align-self: flex-end;
    position: absolute;
  }

  &-left {
    align-self: flex-start;
  }
}

.ec-error-text-input {
  color: rgb(224, 63, 63);
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
}
</style>
