<template>
  <div>
    <div class="email-sent-ico ec-d-flex ec-flex-column ec-align-items-center">
      <span class="ec-ico ec-ico-stack ec-ico-complete ec-ico--green">
        <span class="ec-ico ec-ico-stack ec-ico-tick ec-ico--white"></span>
      </span>
      <h2 class="ec-color-green">
        <span v-html="title"></span>
      </h2>
    </div>
    <div class="ec-mb-3 ec-d-flex ec-flex-column ec-align-items-center">
      <span v-html="subtitle"></span>
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'SuccessFeedback',

  props: {
    title: String,
    subtitle: String,
  },
};
</script>

<style lang="scss" scoped>
  .email-sent-ico {
    margin-bottom: 2.5rem;

    .ec-ico-complete {
      height: 3.5rem;
      width: 3.5rem;
      margin-bottom: 0.875rem;
      display: flex;
      align-items: center;

      .ec-ico-tick {
        height: 2rem;
        width: 2rem;
      }
    }
  }
</style>
