<template>
  <div class="self-serve-activation">
    <i class="ec-ico ec-ico-error ec-ico--red self-serve-activation__icon" />

    <h2
      v-if="isUserNotActivated"
      class="self-serve-activation__header"
    >{{ $gettext('User not activated') }}</h2>

    <h2
      v-else
      class="self-serve-activation__header"
    >{{ $gettext('Expired link') }}</h2>

    <div class="self-serve-activation__text">
      <p v-if="isUserNotActivated">{{ $gettext('To activate your account click below') }}</p>

      <p v-else>{{ $gettext('The activation link has expired. To receive a new link and activate your account, click below') }}</p>
    </div>

    <div class="ec-d-flex ec-flex-column ec-align-items-center">
      <div class="self-serve-activation__btn-wrapper">
        <button @click="activateAccount" type="button" class="ec-btn ec-btn-primary self-serve-activation__btn">
          <span>{{ $gettext('Activate Account') }}</span>
        </button>
      </div>
      <hr class="self-serve-activation__hr">
      <button type="button" class="ec-btn ec-btn-link" @click="back">
        <span class="ec-text-body self-serve-activation__btn-link-text">{{ $gettext('Back to login') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
const {
  huha,
  addHuhaInteraction,
} = window;

export default {
  name: 'SelfServeActivationInfoPage',
  props: {
    errorActivation: Object,
    email: String,
    isUserNotActivated: Boolean,
    huhaCategory: {
      type: String,
      required: true,
    },
  },
  created() {
    this.huhaTask = huha.getTask('Activate Account');
  },
  methods: {
    activateAccount() {
      addHuhaInteraction(this.huhaTask);
      huha.createEvent(
        'Click Activate Account Expired Link/Inactive Acc Notification Page',
        'Activate Account Expired Link/Inactive Acc Notification Page',
        'Click',
        this.huhaCategory,
        '',
        this.huhaTask,
      );
      this.$emit('activate-account');
    },
    back() {
      addHuhaInteraction(this.huhaTask);
      huha.createEvent(
        'Click Back to Login Expired Link/Inactive Acc Notification Page',
        'Back to Login Expired Link/Inactive Acc Notification Page',
        'Click',
        this.huhaCategory,
        '',
        this.huhaTask,
      );
      this.$emit('logout');
    },
  },
};
</script>

<style lang="scss" scoped>
.self-serve-activation {
  text-align: center;

  &__icon {
    height: 40px;
    width: 40px;
    display: block;
    margin: 0 auto 16px;
  }

  &__header {
    color: rgb(224, 63, 63);
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 32px;
  }

  &__error {
    margin-bottom: 30px;
  }

  &__btn-wrapper {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__btn {
    max-width: 16rem;
    flex-basis: 16rem;
    flex-grow: 1;
  }

  &__btn-link-text {
    color: inherit;
  }

  &__hr {
    width: 100%;
  }
}
</style>
