<template>
  <div class="auth-wrapper-bg ec-bg-color-gray7">
    <div class="ec-container-fluid ec-p-0 ec-box-shadow1 auth-wrapper" :class="{ 'hide-tips': hideTips }">
      <div class="ec-row ec-no-gutters ec-justify-content-center">
        <div class="ec-bg-color-white auth-wrapper__main" :class="{ 'ec-col-md-6': !hideTips }">
          <div class="auth-wrapper__main__content">
            <div class="logo"><img :src="logoSrc" :alt="$gettext('Logo')"></div>
            <slot></slot>
          </div>
        </div>
        <div class="auth-wrapper__tip" :class="{ 'ec-col-md-6': !hideTips }"
             :style="authPanelTipBackground" :hidden="hideTips">
          <div class="auth-wrapper__tip__gradient ec-bg-color-gradient-135">
            <div class="auth-wrapper__tip__content">
              <h2 class="tip ec-color-white ec-mb-0">
                <transition name="translate-tip" mode="out-in">
                  <span :key="tipIndex" class="tip__content">{{ tipMessage }}</span>
                </transition>
              </h2>
              <div class="next-tip">
                <a href="#" class="ec-color-white ec-text-uppercase" @click.prevent="nextTip">
                  <span>{{ $gettext('Next tip') }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/i18n/config';

export default {
  name: 'AuthWrapper',

  props: {
    backgroundImgSrc: String,
    logoSrc: String,
    hideTips: {
      type: Boolean,
      default: false,
    },
    isEbury: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      authPanelTipBackground: {
        background: this.backgroundImgSrc ? `url('${this.backgroundImgSrc}') 35% center` : null,
      },
      tipIndex: 0,
      tips: {
        ebury: [
          this.$gettext('If you receive instructions to add or update a beneficiary via email, confirm the details with your beneficiary using an alternative method (e.g. phone, video call, in person)'),
          this.$gettext('Ebury will never ask for your password. Do not share your password with anyone'),
          this.$gettext('Use a complex and unique password for your Ebury Online account that is not shared or similar to those used on any other websites'),
          this.$gettext('We will never update Ebury account details by email unless requested by you or included in a Transaction Receipt'),
        ],
        'non-ebury': [
          this.$gettext('If you receive instructions to add or update a beneficiary via email, confirm the details with your beneficiary using an alternative method (e.g. phone, video call, in person)'),
          this.$gettext('We will never ask for your password. Do not share your password with anyone'),
          this.$gettext('Use a complex and unique password for your account that is not shared or similar to those used on any other websites'),
          this.$gettext('We will never update bank account details by email unless requested by you or included in a Transaction Receipt'),
        ],
      },
    };
  },

  computed: {
    /**
     * Returns a tip according to the current `tipIndex` value.
     */
    tipMessage() {
      return this.tips[this.isEbury ? 'ebury' : 'non-ebury'][this.tipIndex];
    },
  },

  created() {
    // The first tip to show is selected randomly
    const numOfTips = this.tips[this.isEbury ? 'ebury' : 'non-ebury'].length;
    this.tipIndex = Math.floor(Math.random() * Math.floor(numOfTips));
  },

  methods: {
    /**
     * Sets into `tipIndex` the index of the next tip to display.
     */
    nextTip() {
      const numOfTips = this.tips[this.isEbury ? 'ebury' : 'non-ebury'].length;
      this.tipIndex = (this.tipIndex + 1) % numOfTips;
    },
  },
};
</script>

<style lang="scss" scoped>
  .auth-wrapper-bg {
    height: 100%;

    .auth-wrapper.ec-container-fluid {
      .auth-wrapper__tip__content,
      .auth-wrapper__main__content {
        margin-left: auto;
        margin-right: auto;
      }

      .auth-wrapper__main__content {
        max-width: 512px;
      }

      .auth-wrapper__tip__content {
        max-width: 420px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .auth-wrapper__tip {
        display: none;
        height: 592px;

        .auth-wrapper__tip__gradient {
          height: 100%;
          padding: 2.5rem 3rem;
          opacity: 0.9;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .tip {
            position: relative;

            .tip__content {
              position: absolute;
              bottom: 0;
            }
          }

          .next-tip {
            margin-top: 2.5rem;
          }

          .translate-tip-enter-active,
          .translate-tip-leave-active {
            transition: all .5s ease-out;
          }

          .translate-tip-enter {
            transform: translateX(100%);
          }

          .translate-tip-leave-to {
            transform: translateX(-100%);
          }
        }
      }

      .auth-wrapper__main {
        min-height: 592px;
        padding: 2.5rem 3rem;

        .logo {
          max-width: 17rem;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 2.5rem;
          text-align: center;

          img {
            max-width: 100%;
            height: 60px;

            &[src$='svg'] {
              width: 100%;
            }
          }
        }
      }
    }
  }

@media only screen and (min-width: 768px) {
  .auth-wrapper-bg {
    .auth-wrapper.ec-container-fluid {
      .auth-wrapper__tip {
        display: block;
        order: 1;

        .auth-wrapper__tip__content {
          max-width: inherit;
          margin-left: inherit;
          margin-right: inherit;
        }
      }

      .auth-wrapper__main {
        order: 2;
      }
    }
  }
}

@media (min-width: 1025px) {
  .auth-wrapper-bg {
    display: flex;
    align-items: center;

    .auth-wrapper.ec-container-fluid {
      width: 1024px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      overflow: hidden;

      &.hide-tips {
        width: 512px;
      }

      .auth-wrapper__main {
        padding: 2.5rem 5rem;
      }
    }
  }
}

@media (max-width: 1025px) {
  .auth-wrapper.ec-container-fluid {
    box-shadow: none;
  }
}
</style>
