<template>
  <div class="ebo-toggle-btn ec-row ec-align-items-center ec-mx-0">
    <div
      class="ec-mr-2 ec-text-small ec-font-weight-bold"
      data-test="ebo-toggle-btn__status-text"
      v-if="showStatus"
    >
      {{ statusText }}
    </div>
    <div>
      <input
        type="checkbox"
        :id="checkboxId"
        class="ebo-toggle-btn__checkbox ec-invisible"
        ref="toggleCheckbox"
        :checked="isChecked"
        @change="$emit('change', $event.target.checked)"
      />
      <label
        :for="checkboxId"
        class="ebo-toggle-btn__label ec-mb-0"
        data-test="ebo-toggle-btn__label"
      />
    </div>
  </div>
</template>

<script>
import '@/i18n/config';

export default {
  name: 'ToggleButton',
  props: {
    showStatus: Boolean,
    isChecked: Boolean,
  },
  computed: {
    statusText() {
      return this.isChecked ? this.$gettext('Enabled') : this.$gettext('Disabled');
    },
    checkboxId() {
      const checkboxSuffix = (Math.random() + 1).toString(36).substring(7);
      return `ebo-toggle-button-checkbox-${checkboxSuffix}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.ebo-toggle-btn {
  &__checkbox{
    height: 0;
    width: 0;
    position: absolute;

    &:checked + label {
      background: var(--ch-main4);

      &:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
  }

  &__label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: var(--ch-gray6);
    display: block;
    border-radius: 10px;
    position: relative;
    transition: 0.4s;

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto 0;
      left: 2px;
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 15px;
      transition: 0.4s;
    }

    &:active:after {
      width: 15px;
    }
  }
}
</style>
