const { $http } = window;

/**
 * Login service
 */
const LoginService = {
  /**
   * Request to check if hash is valid
   */
  validHashRequest(hash) {
    const url = `/password/reset/valid-hash/${hash}`;
    return $http.get(url);
  },

  /**
   * Request to reset password
   */
  resetPasswordRequest(password, hash) {
    const url = `/password/reset/valid-hash/${hash}`;
    const payload = { password };
    return $http.post(url, payload);
  },

  /**
   * Requests the needed info on Inactive Account page.
   */
  inactiveAccountRequest() {
    const url = '/account/inactive/info/';
    return $http.get(url);
  },

  /**
   * Request to forgot password
   */
  forgotPasswordRequest(email) {
    const url = '/password/reset/';
    const payload = { email };
    return $http.post(url, payload);
  },

  /**
   * Request to validate the token in the account activation process
   *
   * @param hash The hash to validate
   * @returns Response promise
   */
  validateAccountActivationHash(hash) {
    const url = `/api/user/activate/${hash}`;
    return $http.get(url);
  },

  /**
   * Request to set up the new password to activate the account
   *
   * @param password The new password to set up
   * @param hash The hash to validate
   * @returns Response promise
   */
  setUpPassword(password, hash) {
    const url = `/api/user/activate/${hash}`;
    const payload = { password };
    return $http.post(url, payload);
  },

  /**
   * Request to send a message to change the wrong data
   *
   * @param message Indicates the wrong data
   * @param hash The hash to validate
   * @returns Response promise
   */
  changeWrongData(message, hash) {
    const url = `/api/user/wrong-data/${hash}`;
    const payload = { message };
    return $http.post(url, payload);
  },

  getSecurityChallenge(email) {
    const url = `/api/user/self-activation/?email=${encodeURIComponent(email)}`;
    return $http.get(url);
  },

  sendSecurityChallenge(email, questions) {
    const payload = { email, questions };
    const url = '/api/user/self-activation/';
    return $http.post(url, payload);
  },
};

export default LoginService;
