import Vue from 'vue';
import GetTextPlugin from 'vue-gettext';

const defaultLanguage = window.language || 'en';

Vue.use(GetTextPlugin, {
  availableLanguages: {},
  defaultLanguage,
  translations: window.__translations__ || {},
  silent: true,
});
