<template>
  <form method="post" :action="formActionUrlLogin" @submit="checkForm" autocomplete="off">
    <input type='hidden' name='csrfmiddlewaretoken' :value='csrfToken'>
    <input type="hidden" name="timezone" :value="timeZone">

    <!-- Email -->
    <div class="ec-form-group" :class="{ 'ec-is-invalid': !emailIsValid }">
      <label for="username" class="ec-text-input-label"><span>{{ $gettext('Email') }}</span></label>
      <input type="text" class="ec-form-control" id="username" name="username" v-model="email"
             ref="tooltip-email" data-toggle="tooltip" :data-original-title="emailTooltipMsg"
             data-custom-class="ec-tooltip--invalid" data-placement="right" data-container=".auth-wrapper"
             :placeholder="emailPlaceholder" @focus="cleanInputValidation('email')" data-test="login-username">
    </div>

    <!-- Password -->
    <div class="ec-form-group" :class="{ 'ec-is-invalid': !passwordIsValid }">
      <label for="password" class="ec-text-input-label"><span>{{ $gettext('Password') }}</span></label>
      <input type="password" class="ec-form-control" id="password" name="password" v-model="password"
             ref="tooltip-password" data-toggle="tooltip" :data-original-title="passwordTooltipMsg"
             data-custom-class="ec-tooltip--invalid" data-placement="right" data-container=".auth-wrapper"
             :placeholder="passwordPlaceholder" @focus="cleanInputValidation('password')" data-test="login-password">
    </div>

    <!-- Buttons and links -->
    <div class="actions ec-d-flex ec-flex-column ec-align-items-center">
      <div class="btn__wrapper">
        <button type="submit" class="ec-btn ec-btn-primary btn-login" v-if="!loading" data-test="login-submit">
          <span>{{ $gettext('Login') }}</span>
        </button>
        <div class="ec-loading" v-else></div>
      </div>
      <button type="button" class="ec-btn ec-btn-link btn-forgot" @click="goToForgotPassword" :disabled="loading">
        <span class="ec-text-body">{{ $gettext('Forgot your password?') }}</span>
      </button>
      <button v-if="isSelfActivationEnabled" type="button"
        data-test="ebo-login__self-serve-activation-btn"
        class="ec-btn ec-btn-link btn-forgot ec-mt-1rem" @click="goToSelfServeActivation()" :disabled="loading">
        <span class="ec-text-body">{{ $gettext('Activate account') }}</span>
      </button>
      <hr>
      <button v-if="isEbury" type="button" class="ec-btn ec-btn-link btn-trouble" @click="toggleTroubleLogging"
              :disabled="loading">
        <span class="ec-text-body">{{ $gettext('Trouble logging in?') }}</span>
      </button>
    </div>

  </form>
</template>

<script>
import '@/i18n/config';

const {
  validate,
  huha,
  abandonHuhaTask,
} = window;
const $ = window.jQueryEC;

const FORM_PARAM = 'form';
const FORGOT_PASSWORD_PARAM_VALUE = 'forgotten_password';

export const ACTIVE_ACCOUNT_QUERY_PARAM = 'active-account';

export default {
  name: 'Login',

  props: {
    isEbury: Boolean,
    csrfToken: String,
    formActionUrlLogin: String,
    loginError: Boolean,
    isSelfActivationEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      email: null,
      emailIsValid: true,
      emailTooltipMsg: this.$gettext('Invalid email'),
      emailPlaceholder: this.$gettext('john@gmail.com'),
      password: null,
      passwordIsValid: true,
      passwordTooltipMsg: null,
      passwordPlaceholder: this.$gettext('Account password'),
      loading: false,
    };
  },

  methods: {
    /**
     * Cleans any sign of wrong validation in an input.
     *
     * @param input {String} Input which will be cleaned
     */
    cleanInputValidation(input) {
      this[`${input}IsValid`] = true;
      $(this.$refs[`tooltip-${input}`]).tooltip('hide');
      $(this.$refs[`tooltip-${input}`]).tooltip('disable');
    },

    /**
     * Validates an input and actives the error tooltip if it is necessary.
     *
     * @param input {String} Input which will be validated
     * @return {Boolean} If the input is valid
     */
    validateInput(input) {
      let isValid = true;

      if (input === 'email') {
        this.emailTooltipMsg = this.validateEmail(this[input]);
        isValid = !this.emailTooltipMsg;
      } else if (input === 'password') {
        this.passwordTooltipMsg = this.validatePassword(this[input]);
        isValid = !this.passwordTooltipMsg;
      }
      $(this.$refs[`tooltip-${input}`]).tooltip();
      $(this.$refs[`tooltip-${input}`]).tooltip(!isValid ? 'enable' : 'disable');
      this[`${input}IsValid`] = isValid;
      return isValid;
    },

    /**
     * Validates the email input.
     *
     * @param input {String} Input which will be validated
     * @return {String} The text is filled if there is some error's validation
     */
    validateEmail(input) {
      let text;

      if (!text && !this.validate('empty', input)) {
        text = this.$gettext('Email cannot be empty');
      }
      if (!text && !this.validate('email-@', input)) {
        text = this.$gettext('Email address must contain @');
      }
      if (!text && !this.validate('email-dot', input)) {
        text = this.$gettext('Email address must contain .');
      }
      if (!text && !this.validate('email', input)) {
        text = this.$gettext('Invalid email');
      }
      return text;
    },

    /**
     * Validates the password input.
     *
     * @param input {String} Input which will be validated
     * @return {String} The text is filled if there is some error's validation
     */
    validatePassword(input) {
      let text;
      if (!text && !this.validate('empty', input)) {
        text = this.$gettext('Password cannot be empty');
      }
      return text;
    },

    /**
     * Invokes the `validate` function defined inside the `window` object.
     *
     * @param type {String} Type of validation
     * @param text {String} Text to validate
     */
    validate(type, text) {
      return validate(type, text);
    },

    /**
     * Checks form.
     *
     * @param e {Event} Event triggered
     * @return {Boolean} If the form is valid
     */
    checkForm(e) {
      const validEmail = this.validateInput('email');
      const validPassword = this.validateInput('password');
      const formIsValid = validEmail && validPassword;
      if (formIsValid) {
        this.loading = true;
      } else {
        e.preventDefault();
      }
      return formIsValid;
    },

    /**
     * Displays TroubleLogging component.
     */
    toggleTroubleLogging() {
      this.$bus.$emit('toggle-trouble-logging');
    },

    /**
     * Displays a notification by emitting a notification event to the shared bus.
     *
     * @param message {String} Message of the notification
     * @param type {String} Type of the notification
     */
    displayNotification(message, type) {
      this.$bus.$emit('notification', {
        message,
        type,
      });
    },

    /**
     * Goes to ForgotPassword
     */
    goToForgotPassword(showLoginButton = true) {
      this.$bus.$emit('toggle-forgot-password', { showGoToLoginButton: showLoginButton });
    },

    goToSelfServeActivation(email = '') {
      this.$bus.$emit('toggle-self-serve-activation', { emailToActivate: email });
    },
  },

  mounted() {
    const sessionHuhaTask = huha.getTask('Session');
    abandonHuhaTask(sessionHuhaTask);

    if (this.loginError) {
      huha.createEvent(
        'ErrorMsg Inline – Username or Password Not Match',
        'Inline – Username or Password Not Match',
        'ErrorMsg',
        'Login',
        null,
        this.sessionHuhaTask,
      );
      this.displayNotification(this.$gettext('Either your email or password is incorrect.'), 'error');
    }
    const params = new URLSearchParams(window.location.search);
    if (params.has(ACTIVE_ACCOUNT_QUERY_PARAM)) {
      this.goToSelfServeActivation(params.get(ACTIVE_ACCOUNT_QUERY_PARAM));
    }
    if (params.has(FORM_PARAM)) {
      const formParams = params.get(FORM_PARAM);
      if (formParams === FORGOT_PASSWORD_PARAM_VALUE) {
        const showLoginButton = false;
        this.goToForgotPassword(showLoginButton);
      }
    }
  },

  computed: {
    timeZone() {
      /* istanbul ignore next */
      try {
        return new Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .actions {
    .btn__wrapper {
      width: 100%;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0 2rem 0;

      .btn-login {
        max-width: 16rem;
        flex-basis: 16rem;
        flex-grow: 1;
      }
    }

    .ec-btn.ec-btn-link .ec-text-body {
      color: inherit;
    }

    .ec-mt-1rem {
      margin-top: 1rem;
    }

    hr {
      width: 100%;
    }
  }
</style>
