<template>
  <div>
    <ForgotPasswordForm
      v-show="showForgotPasswordForm"
      :show-back-to-login-button="showBackToLoginButton"
    ></ForgotPasswordForm>
    <SuccessFeedback v-show="!showForgotPasswordForm" :title="title" :subtitle="subtitle">
      <div class="actions ec-d-flex ec-flex-column ec-align-items-center" v-if="showBackToLoginButton">
        <div class="btn__wrapper">
          <button type="button" class="ec-btn ec-btn-primary btn-go-login" @click="goToLogin">
            <span>{{ $gettext('Go to login') }}</span>
          </button>
        </div>
      </div>
    </SuccessFeedback>
  </div>
</template>

<script>
import ForgotPasswordForm from './ForgotPasswordForm.vue';
import SuccessFeedback from './SuccessFeedback.vue';

export default {
  name: 'ForgotPassword',

  components: {
    ForgotPasswordForm,
    SuccessFeedback,
  },

  props: {
    showBackToLoginButton: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showForgotPasswordForm: true,
      title: this.$gettext('Email sent'),
      subtitle: this.$gettext('Check your email for instructions on how to reset your password'),
    };
  },

  methods: {
    /**
     * Listens event to switch the components' visibility
     */
    listenEvent() {
      this.$bus.$on('forgot-pass-email-sent', () => {
        this.showForgotPasswordForm = !this.showForgotPasswordForm;
      });
    },

    /**
     * Goes to login
     */
    goToLogin() {
      window.location.assign('/login/');
    },
  },

  created() {
    this.listenEvent();
  },
};
</script>

<style lang="scss" scoped>
  .actions {
    .btn__wrapper {
      width: 100%;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0 1rem 0;

      .btn-go-login {
        max-width: 16rem;
        flex: 100% 1;
      }
    }
  }
</style>
