<template>
  <div class="self-serve-activation-success-page">
    <span class="self-serve-activation-success-page__icon ec-ico ec-ico-stack ec-ico-complete ec-ico--green">
      <span class="self-serve-activation-success-page__icon-tick ec-ico ec-ico-stack ec-ico-tick ec-ico--white"></span>
    </span>

    <h2 class="self-serve-activation-success-page__header">{{ $gettext('Activation email sent') }}</h2>

    <div class="self-serve-activation-success-page__text">
      <span>{{ $gettext('Check your email for instructions on how to activate your account') }}</span>
    </div>

    <button
      type="button"
      class="ec-btn ec-btn-primary self-serve-activation-success-page__btn"
      @click="goToLogin">
      <span>{{ $gettext('Back to Login') }}</span>
    </button>
  </div>
</template>

<script>
const {
  huha,
  completeHuhaTask,
} = window;

export default {
  name: 'SelfServeActivationSuccess',
  props: {
    huhaCategory: {
      type: String,
      required: true,
    },
  },
  created() {
    this.huhaTask = huha.getTask('Activate Account');
    huha.createEvent(
      'View Activation Account Success',
      'Activation Account Success',
      'View',
      this.huhaCategory,
      '',
      this.huhaTask,
    );
    completeHuhaTask(this.huhaTask);
  },
  methods: {
    goToLogin() {
      window.location.assign('/login/');
    },
  },
};
</script>

<style lang="scss" scoped>
.self-serve-activation-success-page {
  text-align: center;

  &__icon {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    margin: 0 auto 16px;
  }

  &__icon-tick {
    height: 32px;
    width: 32px;
  }

  &__header {
    color: rgb(21, 188, 139);
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 32px;
  }

  &__btn {
    width: 100%;
    max-width: 16rem;
  }
}
</style>
