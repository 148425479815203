import Vue from 'vue';
import '@/commons/setup-vue';
import Auth from '@/components/Auth.vue';

const props = {
  isEbury: window.isEbury,
  logoSrc: window.isEbury ? window.logoEburySrc : window.logoBrandSrc,
  backgroundImgSrc: window.backgroundImgSrc,
  loginError: window.loginError,
  formActionUrlLogin: window.formActionUrlLogin,
  formActionUrlForgot: window.formActionUrlForgot,
  csrfToken: window.csrfToken,
  isSelfActivationEnabled: window.isSelfActivationEnabled,
  brandName: window.brandName,
  privacyPolicyUrl: window.privacyPolicyUrl,
  hasGDPRCookieBannerEnabled: window.hasGDPRCookieBannerEnabled,
  gdprCookieName: window.gdprCookieName,
  gdprCookieVersion: window.gdprCookieVersion,
  windowLocation: window.location,
  csrfTokenCookieName: window.csrfTokenCookieName,
};

new Vue({
  render: h => h(Auth, { props }),
}).$mount('#auth-app');
