<template>
  <div class="self-serve-activation-error-page">
    <i class="ec-ico ec-ico-error ec-ico--red self-serve-activation-error-page__icon" />

    <h2 class="self-serve-activation-error-page__header">{{ $gettext('Activation Failed') }}</h2>

    <div class="self-serve-activation-error-page__text">
      <p v-if="isEbury">
        <span>{{ $gettext('The account you have entered can not be activated at this time. If you believe this to be incorrect, contact')/*Translators: The account you have entered can not be activated at this time. If you believe this to be incorrect, contact operationsteam@ebury.com"*/ }}</span>
        &nbsp;<a href="mailto:operationsteam@ebury.com">operationsteam@ebury.com</a>
      </p>

      <span v-else>{{ $gettext('The account you have entered can not be activated at this time. If you believe this to be incorrect, contact support.') }}</span>
    </div>

    <button
      type="button"
      class="ec-btn ec-btn-primary self-serve-activation-error-page__btn"
      @click="goToLogin">
      <span>{{ $gettext('Back to Login') }}</span>
    </button>
  </div>
</template>

<script>
const {
  huha,
  addHuhaInteraction,
  abandonHuhaTask,
} = window;

export default {
  name: 'SelfServeActivationError',
  props: {
    isEbury: {
      type: Boolean,
    },
    huhaCategory: {
      type: String,
      required: true,
    },
  },
  created() {
    this.huhaTask = huha.getTask('Activate Account');
    huha.createEvent(
      'View Activation Account Failed',
      'Activation Account Failed',
      'View',
      this.huhaCategory,
      '',
      this.huhaTask,
    );
    abandonHuhaTask(this.huhaTask);
  },
  methods: {
    goToLogin() {
      addHuhaInteraction(this.huhaTask);
      window.location.assign('/login/');
    },
  },
};
</script>

<style lang="scss" scoped>
.self-serve-activation-error-page {
  text-align: center;

  &__icon {
    height: 40px;
    width: 40px;
    display: block;
    margin: 0 auto 16px;
  }

  &__header {
    color: rgb(224, 63, 63);
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 32px;
  }

  &__btn {
    width: 100%;
    max-width: 16rem;
  }
}
</style>
