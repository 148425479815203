const { location } = window;

const GdprService = {
  binaryIntToConsentString(num) {
    return num.toString(2).padStart(6, '0');
  },
  getPossibleDomains(domain) {
    let domainToParse = domain;
    const domainParts = domainToParse.split('.');
    const domains = domainParts.map((domainPart) => {
      const index = domainToParse.indexOf(domainPart);
      const possibleDomain = domainToParse.slice(index);
      domainToParse = domainToParse.replace(`${domainPart}.`, '');
      return possibleDomain;
    });
    domains.pop();
    return domains;
  },
  parseCookiesNames(cookieString) {
    const cookies = cookieString.split(/; */);

    return cookies.map((cookie) => {
      const cookieName = cookie.split('=')[0];
      return cookieName.trim();
    });
  },
  wipeNonMandatoryCookies(mandatoryCookies = []) {
    const cookies = this.parseCookiesNames(document.cookie);
    const nonMandatoryCookies = cookies.filter((cookie) => !mandatoryCookies.includes(cookie));
    const possibleDomains = this.getPossibleDomains(location.hostname);
    const invalidDate = new Date(0).toUTCString();
    nonMandatoryCookies.forEach((cookie) => {
      possibleDomains.forEach((domain) => {
        document.cookie = `${cookie}=;expires=${invalidDate};path=/;domain=${domain}`;
      });
      // Sometimes cookies are not being removed when we explicit set the domain, so to avoid
      // that we delete them without an explicit domain as fallback
      document.cookie = `${cookie}=;expires=${invalidDate};path=/`;
    });
  },
};

export default GdprService;
