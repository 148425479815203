<template>
  <div class="ebo-loading-container">
    <div class="ebo-loading ec-loading"></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang="scss" scoped>
  .ebo-loading-container {
    width: 100%;
    height: 592px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ebo-loading {
    width: 48px;
    height: 48px;
  }
</style>
