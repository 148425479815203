<template>
  <div>
    <template v-for="(question, index) in questionsAvailable">
      <div v-if="question.available" class="ec-form-group" :key="index" :class="{ 'ec-is-invalid': !!question.errorText }">
        <div class="self-serve-activation-form__input-label-container">
          <label :for="question.id" class="ec-text-input-label">{{ question.title }}</label>
          <label :for="question.id" class="ec-text-small self-serve-activation-form__input-label-container-note" v-if="!!question.note">
            {{ question.note }}
          </label>
        </div>
        <div class="ec-input-group">
          <input
            :id="question.id"
            :type="question.type"
            class="ec-form-control"
            autocomplete="off"
            v-model="question.value"
            :placeholder="question.placeholder"
            @focus="question.errorText = '', addHuhaInteraction(huhaTask)"
            @blur="addHuhaEvent(question)"
            :data-test="`self-serve-activation-form__input-${index}`"
          >
          <div class="ec-input-group-append" v-if="!!question.tooltip">
            <span class="ec-input-group-icon ec-form-control">
              <i
                data-container=".auth-wrapper"
                ref="selfServeActivationFormTooltip"
                :title="question.tooltip"
                class="ec-ico ec-ico-info ec-ico--interactive"
              ></i>
            </span>
          </div>
        </div>
        <label v-if="!!question.errorText" :for="question.id" class="ec-error-text-input" data-test="ec-error-text-input">{{ question.errorText }}</label>
      </div>
    </template>
    <div class="self-serve-activation-form__actions">
      <button
        class="ec-btn ec-btn-secondary self-serve-activation-form__actions-left"
        data-test="self-serve-activation-form__actions-negative"
        @click="back"
      >
        <span>{{ $gettext('Back') }}</span>
        </button>
      <button
        class="ec-btn ec-btn-primary self-serve-activation-form__actions-right"
        data-test="self-serve-activation-form__actions-positive"
        @click="checkForm"
      >
        <span>{{ $gettext('Submit') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {
  COMPANY_REGISTRATION_NUMBER,
  SIGNUP_REFERENCE,
  ACCOUNT_POSTCODE,
  TRADE_OR_PAYMENT_REF,
  MOST_RECENT_TRADE_OR_PAYMENT_REF,
  CONTACT_DATE_OF_BIRTH,
  DEALER_LAST_NAME,
} from '../constants';

const $ = window.jQueryEC;
const {
  addHuhaError,
  huha,
  addHuhaInteraction,
} = window;

export default {
  name: 'SelfServeActivationFormView',
  props: {
    questions: {
      type: Array,
      required: true,
    },
    huhaCategory: {
      type: String,
      required: true,
    },
    brandName: String,
  },
  data() {
    return {
      questionsData: [
        {
          code: COMPANY_REGISTRATION_NUMBER,
          value: '',
          available: !!this.findQuestion(COMPANY_REGISTRATION_NUMBER),
          title: this.$gettext('Company registration number'),
          huhaLabel: 'Company Registration Number',
          errorText: '',
          placeholder: '',
          note: '',
          tooltip: this.$gettextInterpolate(
            this.$gettext('Please enter your company registration number with or without the country letter prefix. This is the registration number for the company and will usually be in the form XX1111111. Please note this is not your %{ brand } account or client number.'),
            { brand: this.brandName },
          ),
          validation: ['noSpaces', 'empty'],
          type: 'text',
          id: 'company-registration-number',
        },
        {
          code: SIGNUP_REFERENCE,
          value: '',
          available: !!this.findQuestion(SIGNUP_REFERENCE),
          title: this.$gettext('Signup Reference'),
          huhaLabel: 'Signup Reference',
          errorText: '',
          placeholder: '',
          note: '',
          tooltip: this.$gettext('This can be found on your registration emails in the top right corner marked as Reference Code and will usually be 6 or 20 characters long'),
          validation: ['empty'],
          type: 'text',
          id: 'signup-reference',
        },
        {
          code: ACCOUNT_POSTCODE,
          value: '',
          available: !!this.findQuestion(ACCOUNT_POSTCODE),
          title: this.$gettext('Post code'),
          huhaLabel: 'Account Postcode',
          errorText: '',
          placeholder: '',
          note: '',
          tooltip: '',
          validation: ['empty'],
          type: 'text',
          id: 'account-postcode',
        },
        {
          code: TRADE_OR_PAYMENT_REF,
          value: '',
          available: !!this.findQuestion(TRADE_OR_PAYMENT_REF),
          title: this.$gettext('Most recent Trade/Payment Reference'),
          huhaLabel: 'Most Recent Trade/Payment Reference',
          errorText: '',
          placeholder: '',
          note: this.$gettext('Last 30 days'),
          tooltip: this.$gettext('You can find a valid trade or payment reference on a Transaction Receipt or Payment Instruction sent to you in the last 30 days'),
          validation: ['empty'],
          type: 'text',
          id: 'most-recent-trade-payment-reference',
        },
        {
          code: MOST_RECENT_TRADE_OR_PAYMENT_REF,
          value: '',
          available: !!this.findQuestion(MOST_RECENT_TRADE_OR_PAYMENT_REF),
          title: this.$gettext('Trade/Payment Reference'),
          huhaLabel: 'Trade/Payment Reference',
          errorText: '',
          placeholder: '',
          note: this.$gettext('Most Recent'),
          tooltip: this.$gettext('You can find your trade or payment reference on the most recent Transaction Receipt or Payment Instruction document sent to you'),
          validation: ['empty'],
          type: 'text',
          id: 'trade-payment-reference',
        },
        {
          code: CONTACT_DATE_OF_BIRTH,
          value: '',
          available: !!this.findQuestion(CONTACT_DATE_OF_BIRTH),
          title: this.$gettext('Date of birth'),
          huhaLabel: 'Contact Date of Birth',
          errorText: '',
          placeholder: 'dd/mm/yyyy',
          note: '',
          tooltip: '',
          validation: ['empty'],
          type: 'date',
          id: 'contact-date-of-birth',
        },
        {
          code: DEALER_LAST_NAME,
          value: '',
          available: !!this.findQuestion(DEALER_LAST_NAME),
          title: this.$gettext('Dealer\'s last name'),
          huhaLabel: 'Dealer\'s Last Name',
          errorText: '',
          placeholder: '',
          note: '',
          tooltip: '',
          validation: ['empty'],
          type: 'text',
          id: 'delear-last-name',
        },
      ],
      huhaTask: null,
    };
  },
  computed: {
    questionsAvailable() {
      return this.questionsData.filter(item => item.available);
    },
  },
  created() {
    this.huhaTask = huha.getTask('Activate Account');
  },
  mounted() {
    $(this.$refs.selfServeActivationFormTooltip).tooltip();
  },
  methods: {
    addHuhaInteraction,
    addHuhaEvent(question) {
      huha.createEvent(`Set ${question.huhaLabel}`, question.huhaLabel, 'Set', this.huhaCategory, question.value, this.huhaTask);
    },
    findQuestion(code) {
      return this.questions.find(item => item.code === code) || false;
    },
    findErrors() {
      return this.questionsAvailable.find(item => item.errorText !== '') || false;
    },
    checkForm() {
      huha.createEvent('Submit Form', 'Form', 'Submit', this.huhaCategory, '', this.huhaTask);
      this.addHuhaInteraction(this.huhaTask);
      this.questionsAvailable.forEach((question) => {
        question.validation.forEach((validation) => {
          this.checkValidation(validation, question);
        });
      });
      if (!this.findErrors()) {
        this.$emit('sumbit', this.questionsAvailable.map(({ value, code }) => ({ value, code })));
      }
    },
    checkValidation(validation, question) {
      if (validation === 'empty') {
        this.checkEmpty(question);
      }
      if (validation === 'noSpaces') {
        this.checkNoSpaces(question);
      }
    },
    checkEmpty(question) {
      if (question.value.trim() === '') {
        this.addHuhaError();
        question.errorText = this.$gettext('This field cannot be empty');
      }
    },
    checkNoSpaces(question) {
      if (/\s/.test(question.value)) {
        this.addHuhaError();
        question.errorText = this.$gettext('This field does not allow spaces');
      }
    },
    addHuhaError() {
      addHuhaError(this.huhaTask);
    },
    back() {
      this.addHuhaInteraction(this.huhaTask);
      huha.createEvent('Click Back to Login Form Page', 'Back to Login Form Page', 'Click', this.huhaCategory, '', this.huhaTask);
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.self-serve-activation-form {
    &__input-label-container {
      display: flex;
      flex-direction: column;

      &-note {
        align-self: flex-end;
        position: absolute;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;

      &-right {
        align-self: flex-end;
        position: absolute;
      }

      &-left {
        align-self: flex-start;
      }
  }
}

.ec-error-text-input {
  color: rgb(224, 63, 63);
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
}
</style>
