<template>
    <div
      class="ebo-gdpr-policy-overview-banner ec-modal ec-modal-open ec-align-items-center ec-justify-content-center"
    >
      <div
        class="ebo-gdpr-policy-overview-banner__content ec-modal-content"
        :class="{'ebo-gdpr-policy-overview-banner__profile-fix': isInProfile}"
      >
        <header class="ebo-gdpr-policy-overview-banner__header ec-modal-header">
          <h1 class="ec-modal-title">{{ $gettext('Privacy Overview') }}</h1>
          <button type="button" class="ec-close" data-test="ebo-gdpr-policy-overview-banner__close-btn" @click="$emit('close')"></button>
        </header>
        <main class="ebo-gdpr-policy-overview-banner__main ec-mx-4">
          <p
            class="ec-ellipsis--in-2-lines"
            data-test="ebo-gdpr-policy-overview-banner__text"
            :class="{ 'ec-ellipsis': isEllipsisSet }"
          >
            {{ $gettext('This website uses cookies to improve your experience while you navigate through the website. Out of these, the cookies that are categorised as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyse and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may affect your browsing experience.') }}
          </p>
          <button
            type="button"
            class="ebo-gdpr-policy-overview-banner__ellipsis-toggle ec-mb-3 ec-btn-link ec-border-0"
            data-test="ebo-gdpr-policy-overview-banner__ellipsis-toggle"
            @click="toggleEllipsis"
          >
            {{ ellipsisToggleText }}
          </button>
          <Accordion
            :show-toggle="true"
            :is-readonly="true"
            :is-open="openedCategories.necessary"
            :is-accepted="isSelected(GDPR_CATEGORIES_FLAG.NECESSARY)"
            @toggle-accordion="onAccordionToggled('necessary', $event)"
          >
            <template v-slot:header>
                {{ $gettext('Necessary') }}
            </template>
            <template v-slot:content>
                <p>
                  {{ $gettext('Necessary cookies are absolutely essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously.') }}
                </p>
                <table class="ec-table ec-table-bordered">
                  <thead>
                    <th>{{ $gettext('Cookie') }}</th>
                    <th>{{ $gettext('Duration') }}</th>
                    <th>{{ $gettext('Description') }}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2FA_reminded</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is used to remember multi-factor authentication status for the current device.') }}</td>
                    </tr>
                    <tr>
                      <td>clientIdentifier</td>
                      <td>{{ $gettext('Persistent') }}</td>
                      <td>{{ $gettext('This is used to store the identifier of the client in use.') }}</td>
                    </tr>
                    <tr>
                      <td>csrftoken</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is used to store a token that helps prevent Cross-Site Request Forgery (CSRF) attacks.') }}</td>
                    </tr>
                    <tr>
                      <td>messages</td>
                      <td>{{ $gettext('Session') }}</td>
                      <td>{{ $gettext('This is used to store messages that are going to be displayed in certain parts of the website as notifications, i.e. when the user is redirected to login page after session timeout.') }}</td>
                    </tr>
                    <tr>
                      <td>privacy_consent</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is used to store whether or not the user has consented to the use of cookies. It does not store any personal data.') }}</td>
                    </tr>
                    <tr>
                      <td>Session</td>
                      <td>{{ $gettext('Session') }}</td>
                      <td>{{ $gettext('This is used to store session information after the user logs in.') }}</td>
                    </tr>
                    <tr>
                      <td>sessionid</td>
                      <td>{{ $gettext('Session') }}</td>
                      <td>{{ $gettext('This cookie is used to store a session ID after the user logs in.') }}</td>
                    </tr>
                  </tbody>
                </table>
            </template>
          </Accordion>
          <Accordion
            :show-toggle="true"
            :is-open="openedCategories.functional"
            :is-accepted="isSelected(GDPR_CATEGORIES_FLAG.FUNCTIONAL)"
            @toggle-accordion="onAccordionToggled('functional', $event)"
            @toggle-change="onToggleButtonChange(GDPR_CATEGORIES_FLAG.FUNCTIONAL)"
          >
            <template v-slot:header>
                {{ $gettext('Functional') }}
            </template>
            <template v-slot:content>
                <p>
                  {{ $gettext('Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.') }}
                </p>
                <table class="ec-table ec-table-bordered">
                  <thead>
                    <th>{{ $gettext('Cookie') }}</th>
                    <th>{{ $gettext('Duration') }}</th>
                    <th>{{ $gettext('Description') }}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>intercom-device-id-[id]</td>
                      <td>{{ $gettext('9 months') }}</td>
                      <td>{{ $gettext('This cookie is set by Intercom and it is used to identify the device interacting with Intercom Messenger in order to improve security.') }}</td>
                    </tr>
                    <tr>
                      <td>intercom-id-[id]</td>
                      <td>{{ $gettext('9 months') }}</td>
                      <td>{{ $gettext('This cookie is set by Intercom and it is an anonymous visitor identifier.') }}</td>
                    </tr>
                    <tr>
                      <td>intercom-session-[id]</td>
                      <td>{{ $gettext('1 week') }}</td>
                      <td>{{ $gettext('This cookie is set by Intercom and it is an identifier for each unique browser session. The user can access their conversations and have data communicated on logged out pages for 1 week, as long as the session is not intentionally terminated with the logout.') }}</td>
                    </tr>
                  </tbody>
                </table>
            </template>
          </Accordion>
          <Accordion
            :show-toggle="true"
            :is-open="openedCategories.performance"
            :is-accepted="isSelected(GDPR_CATEGORIES_FLAG.PERFORMANCE)"
            @toggle-accordion="onAccordionToggled('performance', $event)"
            @toggle-change="onToggleButtonChange(GDPR_CATEGORIES_FLAG.PERFORMANCE)"
          >
            <template v-slot:header>
                {{ $gettext('Performance') }}
            </template>
            <template v-slot:content>
                <p>
                  {{ $gettext('Performance cookies are used to understand and analyse the key performance indexes of the website which helps in delivering a better user experience for the visitors.') }}
                </p>
            </template>
          </Accordion>
          <Accordion
            :show-toggle="true"
            :is-open="openedCategories.analytics"
            :is-accepted="isSelected(GDPR_CATEGORIES_FLAG.ANALYTICS)"
            @toggle-accordion="onAccordionToggled('analytics', $event)"
            @toggle-change="onToggleButtonChange(GDPR_CATEGORIES_FLAG.ANALYTICS)"
          >
            <template v-slot:header>
                {{ $gettext('Analytics') }}
            </template>
            <template v-slot:content>
                <p>
                  {{ $gettext('Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.') }}
                </p>
                <table class="ec-table ec-table-bordered">
                  <thead>
                    <th>{{ $gettext('Cookie') }}</th>
                    <th>{{ $gettext('Duration') }}</th>
                    <th>{{ $gettext('Description') }}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>__insp_dct</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it registers statistical data on users\' behaviour on the website. Used for internal analytics by the website operator.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_lml</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_mslc</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_msld</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_nv</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it is used to make live streaming of video content more efficient.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_pad</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it collects statistics on the user\'s visits to the website, such as the number of visits, average time spent on the website and what pages have been read.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_session_count</td>
                      <td>{{ $gettext('Persistent') }}</td>
                      <td>{{ $gettext('This is set by Inspectlet and it sets an ID for the visitor. This serves to count the number of specific visitors on the website.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_sid</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it is used to identify the visitor and optimise ad-relevance by collecting visitor data from multiple websites.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_slim</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it is used to make live streaming of video content more efficient.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_targlpt</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it is used to remember user session, which optimises the visitor experience on the website. The cookie also allows visitors to leave comments on website content.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_targlpu</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it registers statistical data on users\' behaviour on the website. Used for internal analytics by the website operator.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_uid</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it is used to collect non-personal information on the visitor\'s behaviour and non-personal visitor statistics.') }}</td>
                    </tr>
                    <tr>
                      <td>__insp_wid</td>
                      <td>{{ $gettext('1 year') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and it is used to make live streaming of video content more efficient.') }}</td>
                    </tr>
                    <tr>
                      <td>__tld__</td>
                      <td>{{ $gettext('Session') }}</td>
                      <td>{{ $gettext('This cookie is used to track visitors on multiple websites.') }}</td>
                    </tr>
                    <tr>
                      <td>__ga</td>
                      <td>{{ $gettext('1 year 1 month') }}</td>
                      <td>{{ $gettext('This cookie is set by Google Analytics and it is used to calculate visitor, session, campaign data and keep track of site usage for the site\'s analytics report. The cookie store information anonymously and assign a randomly generated number to identify unique visitors.') }}</td>
                    </tr>
                    <tr>
                      <td>__ga_[id]</td>
                      <td>{{ $gettext('1 year 1 month') }}</td>
                      <td>{{ $gettext('This cookie is set by Google Analytics and it is used to collect data on the number of times an user has visited the website as well as dates for the first and most recent visit.') }}</td>
                    </tr>
                    <tr>
                      <td>pdata</td>
                      <td>{{ $gettext('Session') }}</td>
                      <td>{{ $gettext('This cookie is set by Inspectlet and is used to track user interactions with the website.') }}</td>
                    </tr>
                  </tbody>
                </table>
            </template>
          </Accordion>
          <Accordion
            :show-toggle="true"
            :is-open="openedCategories.advertisement"
            :is-accepted="isSelected(GDPR_CATEGORIES_FLAG.ADVERTISEMENT)"
            @toggle-accordion="onAccordionToggled('advertisement', $event)"
            @toggle-change="onToggleButtonChange(GDPR_CATEGORIES_FLAG.ADVERTISEMENT)"
          >
            <template v-slot:header>
                {{ $gettext('Advertisement') }}
            </template>
            <template v-slot:content>
                <p>
                  {{ $gettext('Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These cookies track visitors across websites and collect information to provide customised ads.') }}
                </p>
            </template>
          </Accordion>
          <Accordion
            :show-toggle="true"
            :is-open="openedCategories.others"
            :is-accepted="isSelected(GDPR_CATEGORIES_FLAG.OTHERS)"
            @toggle-accordion="onAccordionToggled('others', $event)"
            @toggle-change="onToggleButtonChange(GDPR_CATEGORIES_FLAG.OTHERS)"
          >
            <template v-slot:header>
                {{ $gettext('Others') }}
            </template>
            <template v-slot:content>
                <p>
                  {{ $gettext('Other uncategorised cookies are those that are being analysed and have not been classified into a category as yet.') }}
                </p>
            </template>
          </Accordion>
        </main>
        <footer class="ebo-gdpr-policy-overview-banner__footer ec-modal-footer ec-bg-white ec-flex-column ec-align-items-center ec-flex-sm-row ec-justify-content-sm-end">
          <button
            type="button"
            class="ebo-gdpr-policy-overview-banner__accept-btn ec-btn ec-btn-primary ec-mr-0"
            data-test="ebo-gdpr-policy-overview-banner__accept-btn"
            @click="onSaveAndAccept"
          >
            {{ $gettext('Save & Accept') }}
          </button>
        </footer>
      </div>
    </div>
</template>

<script>
import '@/i18n/config';
import Accordion from './Accordion.vue';
import { GdprCategoriesFlag } from './constants';

export default {
  name: 'GdprPrivacyOverviewBanner',
  components: {
    Accordion,
  },
  props: {
    selectedCookieConsent: {
      type: Number,
      default: () => GdprCategoriesFlag.NECESSARY,
    },
    isInProfile: {
      type: Boolean,
      default: false,
    },
    isPrivacyConsentCookieSet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GDPR_CATEGORIES_FLAG: GdprCategoriesFlag,
      openedCategories: {
        necessary: false,
        functional: false,
        performance: false,
        analytics: false,
        advertisement: false,
        others: false,
      },
      acceptedCategories: this.selectedCookieConsent,
      isEllipsisSet: true,
    };
  },
  computed: {
    ellipsisToggleText() {
      return this.isEllipsisSet ? this.$gettext('Show more') : this.$gettext('Show less');
    },
  },
  methods: {
    onSaveAndAccept() {
      this.$emit('accepted', this.acceptedCategories);
    },
    onAccordionToggled(id, value) {
      this.openedCategories = {
        necessary: false,
        functional: false,
        performance: false,
        analytics: false,
        advertisement: false,
        others: false,
      };

      this.openedCategories[id] = value;
    },
    onToggleButtonChange(category) {
      // eslint-disable-next-line no-bitwise
      this.acceptedCategories ^= category;
    },
    toggleEllipsis() {
      this.isEllipsisSet = !this.isEllipsisSet;
    },
    isSelected(category) {
      // eslint-disable-next-line no-bitwise
      return (this.acceptedCategories & category) === category;
    },
  },
  created() {
    if (!this.isPrivacyConsentCookieSet) {
      this.acceptedCategories = Object.values(GdprCategoriesFlag).reduce((accumulator, value) => accumulator + value, 0);
    }
  },
};
</script>

<style lang="scss" scoped>
.ebo-gdpr-policy-overview-banner {
  background: rgba(46, 54, 56, 0.2);
  position: fixed;
  display: flex;

  &__profile-fix {
    margin-left: 100px;
    margin-right: 24px;
  }

  &__ellipsis-toggle {
    font-size: 1rem;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }
  }

  &__content {
    width: calc(100% - 24px);
    max-width: 680px;
    max-height: calc(100% - 24px);
    overflow-y: auto;
  }
}
</style>
