<template>
  <div class="trouble-logging">
    <h3 class="ec-mb-3"><span>{{ $gettext('Trouble logging in?') }}</span></h3>
    <div class="ec-mb-3">
      <span>{{ $gettext('Contact your Relationship Manager or') }}&nbsp;</span>
      <a :href="`mailto:${operationsEmail}`" class="ec-btn ec-btn-link">
        <span class="ec-text-body">{{operationsEmail}}</span>
      </a>
      <span>&nbsp;{{ $gettext('for further assistance.') }}</span>
    </div>
    <hr>
    <div class="ec-text-center">
      <button type="button" class="ec-btn ec-btn-link" @click="backToLogin">
        <span class="ec-text-body">{{ $gettext('Back to login') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { OPERATIONS_EMAIL } from './constants';

export default {
  name: 'TroubleLogging',

  data: () => ({ operationsEmail: OPERATIONS_EMAIL }),

  methods: {
    /**
     * Emits an event to display the login component.
     */
    backToLogin() {
      this.$bus.$emit('toggle-trouble-logging');
    },
  },
};
</script>

<style lang="scss" scoped>
  .trouble-logging {
    .ec-btn.ec-btn-link .ec-text-body {
      color: inherit;
    }

    hr {
      width: 100%;
      margin-top: 2rem;
    }
  }
</style>
