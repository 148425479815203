<template>
  <div class="ec">
    <GdprCookiePolicyBanner v-if="!isPrivacyConsentCookieSet && isGdprCookieBannerShown"
                            @accepted="setPrivacyConsentCookie"
                            @show-overview="showGdprPrivacyOverviewBanner"
                            @rejected="setPrivacyConsentCookie"
                            :privacy-policy-url="privacyPolicyUrl"
    />
    <GdprPrivacyOverviewBanner v-if="isGdprPrivacyOverviewBannerShown"
                              @accepted="setPrivacyConsentCookie"
                              @close="closeGdprPrivacyOverviewBanner"
                              :selected-cookie-consent="selectedCookieConsent"
                              :is-in-profile="isInProfile"
                              :is-privacy-consent-cookie-set="isPrivacyConsentCookieSet"
    />
  </div>
</template>

<script>
/* global getCookie, setCookie, gtag */
import GdprCookiePolicyBanner from './GdprCookiePolicyBanner.vue';
import GdprPrivacyOverviewBanner from './GdprPrivacyOverviewBanner.vue';
import { GdprCategoriesFlag } from './constants';
import GdprService from '../services/gdpr-service';

export default {
  components: {
    GdprCookiePolicyBanner,
    GdprPrivacyOverviewBanner,
  },
  props: {
    isGdprCookieBannerShown: Boolean,
    isGdprPrivacyOverviewBannerShown: Boolean,
    privacyPolicyUrl: String,
    gdprCookieName: String,
    gdprCookieVersion: Number,
    windowLocation: {},
    csrfTokenCookieName: String,
  },
  data() {
    return {
      // Only necessary cookies will be enabled by default
      defaultCookieConsent: GdprCategoriesFlag.NECESSARY,
      selectedCookieConsent: this.getSelectedCookieConsent(),
    };
  },
  computed: {
    isPrivacyConsentCookieSet() {
      return getCookie(this.gdprCookieName);
    },
    isInProfile() {
      return this.windowLocation.pathname === '/profile/';
    },
  },
  methods: {
    closeGdprCookieBanner() {
      this.closeGdprPrivacyOverviewBanner();
      this.$emit('close-gdpr-cookie-banner');
    },
    showGdprPrivacyOverviewBanner() {
      this.$emit('is-gdpr-privacy-overview-banner-shown', true);
    },
    closeGdprPrivacyOverviewBanner() {
      this.$emit('is-gdpr-privacy-overview-banner-shown', false);
    },
    setPrivacyConsentCookie(consent) {
      const cookieValue = {
        v: this.gdprCookieVersion,
        created: Date.now(),
        consent: GdprService.binaryIntToConsentString(consent),
      };
      const cookieValueEncoded = btoa(JSON.stringify(cookieValue));
      setCookie(this.gdprCookieName, cookieValueEncoded);
      this.selectedCookieConsent = consent;
      this.closeGdprCookieBanner();
      gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
      });
      GdprService.wipeNonMandatoryCookies([this.csrfTokenCookieName, this.gdprCookieName]);
      this.windowLocation.reload();
    },
    getSelectedCookieConsent() {
      const cookie = getCookie(this.gdprCookieName);
      if (!cookie) {
        return this.defaultCookieConsent;
      }
      return parseInt(JSON.parse(atob(cookie)).consent, 2);
    },
  },
  created() {
    if (!this.isPrivacyConsentCookieSet) {
      GdprService.wipeNonMandatoryCookies([this.csrfTokenCookieName, this.gdprCookieName]);
    }
  },
};
</script>
