import Vue from 'vue';
import '@/i18n/config';

const { imgPath } = window;

Vue.config.productionTip = false;

Object.defineProperties(Vue.prototype, {
  // Given a image's file name, returns the relative path where it can be found
  $getImgPath: {
    get() {
      return imgFileName => imgPath + imgFileName;
    },
  },
});
