<template>
  <div>
    <self-serve-activation-info-page v-if="isInfoPage" :email="email" :huhaCategory="huhaCategory" :isUserNotActivated="isUserNotActivated" @logout="logout" @activate-account="getQuestions"/>
    <self-serve-activation-form-page v-else-if="isFormPage" :brandName="brandName" :huhaCategory="huhaCategory" :questions="questions" @back="logout" @sumbit="activateAccount" />
    <self-serve-activation-set-email-page v-else-if="isSetEmailPage" @back="backFromSetEmail" @set-email="setEmail" :prefilledEmail="prefilledEmail"/>
    <self-serve-activation-error-page v-else-if="isErrorPage" :isEbury="isEbury" :huhaCategory="huhaCategory" />
    <self-serve-activation-success-page v-else-if="isSuccessPage" :huhaCategory="huhaCategory"/>
    <loading v-else-if="isLoading" />
  </div>
</template>

<script>
import LoginService from '../services/login-service';
import SelfServeActivationInfoPage from './SelfServeActivation/InfoPage.vue';
import SelfServeActivationSetEmailPage from './SelfServeActivation/SetEmail.vue';
import SelfServeActivationFormPage from './SelfServeActivation/Form.vue';
import SelfServeActivationErrorPage from './SelfServeActivation/ErrorPage.vue';
import SelfServeActivationSuccessPage from './SelfServeActivation/SuccessPage.vue';
import Loading from './Loading.vue';
import {
  SELF_SERVE_ACTIVATION_INFO_PAGE,
  SELF_SERVE_ACTIVATION_LOADING_PAGE,
  SELF_SERVE_ACTIVATION_SET_EMAIL_PAGE,
  SELF_SERVE_ACTIVATION_ERROR_PAGE,
  SELF_SERVE_ACTIVATION_FORM_PAGE,
  SELF_SERVE_ACTIVATION_SUCCESS_PAGE,
  CODE_REQUEST_SUCCESS,
  CODE_RESPONSE_LOGIN_USER_NOT_ACTIVATED,
} from './constants';
import { ACTIVE_ACCOUNT_QUERY_PARAM } from './Login.vue';

const { huha, abandonHuhaTask } = window;

export default {
  name: 'SelfServeActivation',
  components: {
    SelfServeActivationInfoPage,
    SelfServeActivationSetEmailPage,
    SelfServeActivationFormPage,
    SelfServeActivationErrorPage,
    SelfServeActivationSuccessPage,
    Loading,
  },
  data() {
    return {
      view: SELF_SERVE_ACTIVATION_INFO_PAGE,
      emailQuestions: '',
      questions: null,
      huhaCategory: '',
    };
  },
  props: {
    errorActivation: Object,
    isEbury: Boolean,
    email: String,
    prefilledEmail: String,
    brandName: String,
  },
  computed: {
    isInfoPage() {
      return this.view === SELF_SERVE_ACTIVATION_INFO_PAGE;
    },
    isLoading() {
      return this.view === SELF_SERVE_ACTIVATION_LOADING_PAGE;
    },
    isFormPage() {
      return this.view === SELF_SERVE_ACTIVATION_FORM_PAGE;
    },
    isSetEmailPage() {
      return this.view === SELF_SERVE_ACTIVATION_SET_EMAIL_PAGE;
    },
    isErrorPage() {
      return this.view === SELF_SERVE_ACTIVATION_ERROR_PAGE;
    },
    isSuccessPage() {
      return this.view === SELF_SERVE_ACTIVATION_SUCCESS_PAGE;
    },
    isUserNotActivated() {
      return !!this.errorActivation && this.errorActivation.code === CODE_RESPONSE_LOGIN_USER_NOT_ACTIVATED;
    },
  },
  created() {
    /* istanbul ignore next */
    const sessionHuhaTask = huha.getTask('Session') || null;
    this.huhaTask = huha.createTask('Activate Account', sessionHuhaTask);

    if (!this.email) {
      this.huhaCategory = 'Activate Account via Login link';
      const urlSearchParams = new URLSearchParams(window.location.search);
      if (urlSearchParams.has(ACTIVE_ACCOUNT_QUERY_PARAM)) {
        this.huhaCategory = 'Activate Account via Activation link';
      }
      this.view = SELF_SERVE_ACTIVATION_SET_EMAIL_PAGE;
    } else {
      if (this.isUserNotActivated) {
        this.huhaCategory = 'Activate Account via User Inactive';
      } else {
        this.huhaCategory = 'Activate Account via Expired link';
      }
      this.emailQuestions = this.email;
      huha.createEvent('Set Email Activation Account', 'Email Activation Account', 'Set', this.huhaCategory, this.emailQuestions, this.huhaTask);
    }
  },
  methods: {
    backFromSetEmail() {
      abandonHuhaTask(this.huhaTask);
      this.$emit('back-from-set-email');
    },
    setEmail(email) {
      this.emailQuestions = email;
      huha.createEvent('Set Email Activation Account', 'Email Activation Account', 'Set', this.huhaCategory, this.emailQuestions, this.huhaTask);
      this.getQuestions();
    },
    getQuestions() {
      this.view = SELF_SERVE_ACTIVATION_LOADING_PAGE;
      LoginService.getSecurityChallenge(this.emailQuestions)
        .then((res) => {
          if (!!res.data.length && res.data.length > 0) {
            this.view = SELF_SERVE_ACTIVATION_FORM_PAGE;
            this.questions = res.data;
          } else {
            this.view = SELF_SERVE_ACTIVATION_ERROR_PAGE;
          }
        })
        .catch(() => {
          this.view = SELF_SERVE_ACTIVATION_ERROR_PAGE;
        });
    },
    activateAccount(data) {
      LoginService.sendSecurityChallenge(this.emailQuestions, data)
        .then((res) => {
          if (res.status === CODE_REQUEST_SUCCESS) {
            this.view = SELF_SERVE_ACTIVATION_SUCCESS_PAGE;
          } else {
            this.view = SELF_SERVE_ACTIVATION_ERROR_PAGE;
          }
        })
        .catch(() => {
          this.view = SELF_SERVE_ACTIVATION_ERROR_PAGE;
        });
    },
    logout() {
      abandonHuhaTask(this.huhaTask);
      window.location.assign('/logout/');
    },
  },
  beforeDestroy() {
    abandonHuhaTask(this.huhaTask);
  },
};
</script>

<style lang="scss" scoped>
.self-serve-activation {
  text-align: center;

  &__icon {
    height: 40px;
    width: 40px;
    margin-bottom: 5px;
  }

  &__error {
    margin-bottom: 30px;
  }

  &__text-error {
    color: rgb(224, 63, 63);
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
  }

  &__btn-wrapper {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0 2rem 0;

  }

  &__btn {
    max-width: 16rem;
    flex-basis: 16rem;
    flex-grow: 1;
  }

  &__btn-link-text {
    color: inherit;
  }

  &__hr {
    width: 100%;
  }
}

</style>
